var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',[_c('div',{staticClass:"content text-center text-md-left"},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"mb-5 mb-md-0",attrs:{"cols":"12","md":"2"}},[_c('h4',[_vm._v("NAVIGATE")]),_vm._l((_vm.navLinks),function(ref,i){
var name = ref.name;
var slug = ref.slug;
return _c('p',{key:i,staticClass:"mb-0"},[_c('router-link',{attrs:{"to":slug}},[_vm._v(_vm._s(name))])],1)})],2),_c('b-col',{staticClass:"mb-5 mb-md-0",attrs:{"cols":"12","md":"2"}},[_c('h4',[_vm._v("PROJECTS")]),_vm._l((_vm.allProjects),function(ref){
var title = ref.title;
var id = ref.id;
var slug = ref.slug;
return _c('p',{key:id,staticClass:"mb-0"},[_c('router-link',{attrs:{"to":("/portfolio/" + slug)},domProps:{"innerHTML":_vm._s(title.rendered)}})],1)})],2),_c('b-col',{staticClass:"mb-5 mb-md-0",attrs:{"cols":"12","md":"2"}},[_c('h4',[_vm._v("OUR SERVICES")]),_vm._l((_vm.allServices),function(ref){
var title = ref.title;
var id = ref.id;
var slug = ref.slug;
return _c('p',{key:id,staticClass:"mb-0"},[_c('router-link',{attrs:{"to":("/services/" + slug)},domProps:{"innerHTML":_vm._s(title.rendered)}})],1)})],2),_c('b-col',{staticClass:"text-center text-md-right",attrs:{"cols":"12","md":"6"}},[_c('h4',[_vm._v("CONTACT US")]),_c('p',[_c('span',[_vm._v("Email: ")]),_c('a',{attrs:{"href":"mailto:info@constec.ca"}},[_vm._v("info@constec.ca")])])])],1),_c('b-row',{staticClass:"align-items-end"},[_c('b-col',{attrs:{"order":"2"}},[_c('p',{staticClass:"m-0"},[_vm._v(" © "+_vm._s(new Date().getFullYear())+" CONSTEC DESIGN CONTRACTING LTD. ")])]),_c('b-col',{staticClass:"mb-3 mb-md-0 d-flex justify-content-center justify-content-md-end align-items-end",attrs:{"order":"1","order-md":"2","cols":"12","md":"6"}},[_c('div',{staticClass:"social-media"},[_c('a',{staticClass:"d-inline-block mr-3",attrs:{"href":"https://www.linkedin.com/company/constecdesign","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"linkedin"}})],1),_c('a',{attrs:{"href":"https://www.instagram.com/constecdesigns/","target":"_blank"}},[_c('b-icon',{attrs:{"icon":"instagram"}})],1)])])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }