<template>
  <div
    class="w-100 d-flex justify-content-center align-items-center"
    style="height: 80vh"
  >
    <div class="text-center">
      <h1>404 page not found</h1>

      <p>
        <router-link to="/">HOME</router-link>
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>